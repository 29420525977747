import { Badge, Button, Dropdown, Modal, notification, Space, Table } from 'antd';
import { Job, Order, Request } from 'types/models';
import { useLocalization } from 'lib/Localization';
import { OrganizationType } from 'types';
import { OrganizationNames } from '../OrganizationNames';
import { maxDateOfDates, minDateOfDates } from 'lib/Helpers/DateTimeHelper';
import { AvailableTabs } from '../Requests';
import { useAccount } from 'lib/Account';
import dayjs from 'dayjs';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { ProgressModal } from 'shared/Requests/ProgressModal';
import { ProviderRequestActionButtons } from 'pages/Provider/Requests/Request/ProviderRequestActionButtons';
import { ProviderRequestTransition, RequestState } from 'types/staffing';
import {
    CaretDownOutlined,
    CaretRightOutlined,
    CheckCircleOutlined,
    CloseCircleTwoTone,
    DownloadOutlined,
    EditOutlined,
    MoreOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { FileIcon, OpenNotifIcon } from 'shared/icons';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { Backend } from 'lib/Http/Backend';
import { useErrorHandler } from 'lib/ErrorHandling';
import { localizeProfession } from 'lib/Helpers/ProfessionHelper';
import { useNavigate } from 'react-router-dom-v5-compat';
import { JobState } from 'types/staffing/JobStateMachine';
import { RequestProgress } from 'shared/Requests/RequestsTable/RequestProgress';
import { userIsProvider } from 'lib/Helpers/UserHelper';
import { downloadExcel, useRequestTab } from 'lib/Request';
import { FileArchive } from 'shared/icons/FileArchive';
import { useRequestActions } from 'lib/hooks/useRequestActions';
import { useLoadPaginatedEntity } from 'lib/hooks/useLoadPaginatedEntity';
import { GenericObject } from 'shared/Contracts';
import { useTableFilterHelper } from 'lib/hooks/useTableFilterHelper';

type Props = {
    activeTab: AvailableTabs;
    actionColumnWidth?: string | number;
    collection: {
        clientOptions: { value: number; label: string }[];
        creatorOptions: { value: number; label: string }[];
        departmentOptions: { value: number; label: string }[];
    };
    onAccept?: () => void;
    organizationType: OrganizationType;
    showProgress?: boolean;
};

interface DataSource {
    id: number;
    client: JSX.Element | string;
    createdAt: string;
    createdBy: string;
    children?: DataSource[];
    key: string;
    location: JSX.Element | string;
    partOfOrder: boolean;
    profession: JSX.Element | string;
    progress: JSX.Element;
    request: Request | null;
    startEnd: string;
    state: string;
}

export const sumRequestValues = (order: Order, getValue: (request: Request) => number) =>
    order.requests.reduce((total, request) => total + getValue(request), 0);

export const RequestTable = ({
    actionColumnWidth,
    activeTab,
    collection,
    onAccept,
    organizationType,
    showProgress,
}: Props) => {
    const { accountUser: user } = useAccount();
    const localeContext = useLocalization();
    const { t, locale } = localeContext;
    const navigate = useNavigate();
    const handleError = useErrorHandler();
    const { getMovablePastJobs, moveJobsToHistory } = useRequestActions();
    const { getUpdatedOrder } = useRequestTab();

    const isProvider = userIsProvider(user);
    const [previewOrder, setPreviewOrder] = useState<Order | undefined>(undefined);
    const [previewRequestId, setPreviewRequestId] = useState<number | undefined>(undefined);
    const [dataSource, setDataSource] = useState<DataSource[]>([]);
    const [responding, setResponding] = useState<number[]>([]);
    const [orders, tableParams, isLoadingOrders, setOrders, setTableParams] = useLoadPaginatedEntity<Order>(
        'orders',
        `/staffing/orders?requestType=${activeTab}`,
        {
            sortOrder: 'descend',
            sortField: 'createdAt',
        },
        getUpdatedOrder
    );
    const { getColumnDatePickerProps, getColumnDateRangePickerProps, getColumnSearchProps, setFilters } =
        useTableFilterHelper();

    const getDataSource = useCallback(
        () =>
            orders.map((order) => {
                const singleRequest = order.auto_created ? order.requests[0] : null;

                const getProfession = (request: Request | null, partOfOrder: boolean) => {
                    if (request && !request.profession) {
                        return '';
                    }

                    return request ? (
                        <a href="#" onClick={(e) => e.preventDefault()}>
                            {partOfOrder && (
                                <FileIcon color="#0096E1" width={13} height={13} style={{ marginRight: '20px' }} />
                            )}
                            <span style={{ fontWeight: singleRequest ? 600 : 400 }}>
                                {localizeProfession(request.profession, locale).name}
                            </span>
                        </a>
                    ) : (
                        <Space>
                            {!!order.external_id && <span style={{ color: '#1677ff' }}>[{order.external_id}]</span>}

                            <span
                                className="request-table-name"
                                style={{
                                    fontWeight: 600,
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                }}
                            >
                                {order.name}
                            </span>
                            {!isProvider && activeTab === AvailableTabs.DRAFT && (
                                <OpenNotifIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e: any) => {
                                        e.stopPropagation();

                                        goToCreatePage(order.id);
                                    }}
                                />
                            )}
                        </Space>
                    );
                };

                const createSource = (request: Request | null, partOfOrder = false): DataSource => {
                    let key = `order-${order.id}`;

                    if (request) {
                        key += `-request-${request.id}`;
                    }

                    let requestState = request?.state ?? '';

                    if (isProvider) {
                        let providerRequest = request?.provider_requests.find(
                            (provider_request) => provider_request.provider_id === user.profiles.agent?.provider_id
                        );

                        if (
                            [RequestState.POSTED, RequestState.PENDING].includes(request?.state as RequestState) &&
                            providerRequest
                        ) {
                            requestState = providerRequest.state;
                        }
                    }

                    let requestStartEnd = '';

                    if (request) {
                        const minCalculatedShiftDates = calculateShiftDates(request.jobs, minDateOfDates);
                        const maxCalculatedShiftDates = calculateShiftDates(request.jobs, maxDateOfDates);

                        requestStartEnd =
                            minCalculatedShiftDates === maxCalculatedShiftDates
                                ? minCalculatedShiftDates
                                : minCalculatedShiftDates + ' - ' + maxCalculatedShiftDates;
                    }

                    return {
                        id: order.id,
                        key,
                        client: isProvider ? (
                            <OrganizationNames
                                request={(request || order.requests[0]) as Request}
                                organizationType={organizationType}
                            />
                        ) : (
                            ''
                        ),
                        createdAt: toDateString(new Date(order.created_at)),
                        createdBy:
                            (request || order).created_by.first_name + ' ' + (request || order).created_by.last_name,
                        location: request?.address?.address ?? getOrderAddressString(order),
                        partOfOrder,
                        profession: getProfession(request, partOfOrder),
                        progress: <RequestProgress order={order} request={request} />,
                        request,
                        startEnd: requestStartEnd,
                        state: requestState,
                    };
                };

                const source = createSource(singleRequest);

                if (!singleRequest) {
                    let orderJobs: any[] = [];

                    order.requests.forEach((request) => {
                        orderJobs.push(...request.jobs);
                    });

                    let sourceStartEnd = '';
                    const minOrderJobsCalculatedShiftDates = calculateShiftDates(orderJobs, minDateOfDates);
                    const maxOrderJobsCalculatedShiftDates = calculateShiftDates(orderJobs, maxDateOfDates);

                    if (orderJobs.length) {
                        sourceStartEnd =
                            minOrderJobsCalculatedShiftDates === maxOrderJobsCalculatedShiftDates
                                ? minOrderJobsCalculatedShiftDates
                                : minOrderJobsCalculatedShiftDates + ' - ' + maxOrderJobsCalculatedShiftDates;
                    }

                    source.startEnd = sourceStartEnd;
                    source.children = order.requests.map((request) => createSource(request, true));
                }

                return source;
            }),
        [orders]
    );

    useEffect(() => {
        document.querySelectorAll('.request-table-name').forEach((element) => {
            let htmlElement = element as HTMLElement;

            switch (true) {
                case htmlElement.offsetHeight > 22 && htmlElement.offsetHeight <= 44:
                    htmlElement.classList.remove('request-table-name');

                    htmlElement.style.position = 'absolute';
                    htmlElement.style.top = '8px';
                    htmlElement.style.maxWidth = '90%';

                    if (htmlElement.parentElement) {
                        htmlElement.parentElement.style.width = htmlElement.offsetWidth + 'px';
                    }

                    break;
                case htmlElement.offsetHeight > 44:
                    htmlElement.classList.remove('request-table-name');

                    htmlElement.style.position = 'absolute';
                    htmlElement.style.top = '6px';
                    htmlElement.style.maxWidth = '90%';
                    htmlElement.style.lineHeight = '15px';

                    if (htmlElement.parentElement) {
                        htmlElement.parentElement.style.width = htmlElement.offsetWidth + 'px';
                    }

                    break;
                default:
                    break;
            }
        });
    });

    useEffect(() => {
        setDataSource(getDataSource());
    }, [orders, getDataSource]);

    const updateOrder = (order: Order) =>
        setOrders((prevState) => {
            const localState = [...prevState];
            const orderIndex = localState.findIndex(({ id }) => id === order.id);

            if (orderIndex > -1) {
                localState.splice(orderIndex, 1, order);
            }

            return localState;
        });

    const removeOrder = (order: Order) =>
        setOrders((prevState) => {
            const localState = [...prevState];
            const orderIndex = localState.findIndex(({ id }) => id === order.id);

            if (orderIndex > -1) {
                localState.splice(orderIndex, 1);
            }

            return localState;
        });

    const getOrderAddressString = (order: Order) => {
        let orderAddressess = [
            ...new Set(
                order.requests.map((request) => {
                    return request.address?.address;
                })
            ),
        ];

        return orderAddressess.length > 1 ? (
            <>
                {orderAddressess[0]},
                <span className="orderAddressessLenghtSpan">+{orderAddressess.length - 1} more</span>
            </>
        ) : (
            <>{orderAddressess[0]}</>
        );
    };

    const goToCreatePage = (orderId: number, addRequest: boolean = false, requestId?: number) => {
        let url = `/client/requests/create?order=${orderId}`;

        if (requestId) {
            url += `&request=${requestId}`;
        }

        if (addRequest) {
            url += '&addRequest';
        }

        navigate(url);
    };

    const goToRequestPage = (requestId: number, edit: boolean = false) => {
        let url = `/${organizationType}/requests/${requestId}?`;

        if (activeTab === AvailableTabs.HISTORY) {
            url += 'history&';
        }

        if (edit) {
            url += 'edit&';
        }

        navigate(url);
    };

    const providerFilterOptions = collection.clientOptions || [];

    const toDateString = (date: Date, format = 'DD.MM.YYYY') =>
        dayjs(date, { locale: locale.shortCode }).format(format);

    const calculateShiftDates = (
        jobs: Job[],
        DateReducer: (dates: Date[]) => Date,
        format: string = 'ddd DD.MM'
    ): string => {
        const dates = jobs.map((job) => new Date(job.date)) as Date[];

        return dates.length > 0 ? toDateString(DateReducer(dates), format) : '';
    };

    const moveRecordJobsToHistory = async (record: DataSource) => {
        const pastJobs = getMovablePastJobs(record.request as Request);
        const response = await moveJobsToHistory(pastJobs.map(({ id }) => id));

        if (!response) {
            return;
        }

        const localOrders = [...orders];

        const index = localOrders.findIndex(({ id }) => id === record.id);

        if (index === -1) {
            return;
        }

        if (localOrders[index].requests.length === 1) {
            localOrders.splice(index, 1);
        } else {
            const requestIndex = localOrders[index].requests.findIndex(({ id }) => record.request?.id === id);

            if (index === -1) {
                return;
            }

            localOrders[index].requests.splice(requestIndex, 1);
        }

        setOrders(localOrders);
    };

    const renderProviderActionButton = (record: DataSource) => {
        const hasOpenJobs = record.request?.jobs?.some(({ state }) => state === JobState.OPEN);

        const hasNoPastJobs = (request: Request) => getMovablePastJobs(request).length === 0;

        const isRequestAvailable = hasOpenJobs && hasNoPastJobs(record.request as Request);
        const isOrder = !record.request;

        if (
            activeTab === AvailableTabs.ACTIVE ||
            (activeTab === AvailableTabs.PENDING && !isRequestAvailable && !isOrder)
        ) {
            return renderActionColumn(record.key, [
                {
                    icon: <FileArchive />,
                    key: `${record.key}-move-to-history`,
                    label: t('Move to History'),
                    onClick({ domEvent }: any) {
                        domEvent.stopPropagation();

                        Modal.confirm({
                            title: null,
                            icon: null,
                            content: (
                                <>
                                    <p>{t('Are you sure you want to move this request to History?')}</p>
                                    <p style={{ color: 'darkgrey' }}>{t('Only finished shifts will be affected.')}</p>
                                </>
                            ),
                            okText: t('Yes'),
                            cancelText: t('No'),
                            centered: true,
                            onOk: () => moveRecordJobsToHistory(record),
                        });
                    },
                },
            ]);
        }

        const updateRequests = (requests: Request[]) => {
            const order = orders.find(({ id }) => id === record.id);

            if (!order) {
                return;
            }

            const orderRequests = structuredClone(order.requests);

            requests.forEach((request) => {
                const requestIndex = orderRequests.findIndex(({ id }) => id === request.id);

                if (requestIndex === -1) {
                    return;
                }

                orderRequests.splice(requestIndex, 1, request);
            });

            updateOrder({
                ...order,
                requests: orderRequests,
            });
        };

        if (!record.request) {
            const order = orders.find(({ id }) => id === record.id);

            if (!order) {
                return;
            }

            const orderHasOpenJobRequests = order.requests?.some((request) =>
                request.jobs?.some(({ state }) => state !== JobState.CANCELED)
            );
            const orderHasNonPastJobRequests = order.requests?.some(
                (request) => getMovablePastJobs(request).length !== request.jobs.length
            );

            if (!responding.includes(record.id) && orderHasOpenJobRequests && orderHasNonPastJobRequests) {
                const providerRequestsURL = `/staffing/providerRequests/applyTransitionToMany`;

                //@ts-ignore
                const providerRequestIds = order.requests.reduce((carry, request) => {
                    const requestProviderIds = request.provider_requests.map(({ id }) => id);

                    return [...carry, ...requestProviderIds];
                }, []);

                const respond = async (
                    { domEvent }: any,
                    transition: ProviderRequestTransition,
                    callback: () => void
                ) => {
                    setResponding((prevState) => [...prevState, record.id]);

                    try {
                        domEvent.stopPropagation();

                        const response = await Backend.put(`${providerRequestsURL}/${transition}`, {
                            ids: providerRequestIds,
                        });

                        if (response.status === Backend.responseStatus.HTTP_OK) {
                            updateRequests(response.data.requests);

                            callback();
                        }
                    } catch (error) {
                        console.error(error);
                        handleError(error);
                    } finally {
                        setResponding((prevState) => {
                            const index = prevState.findIndex((id) => id === record.id);

                            if (index === -1) {
                                return prevState;
                            }

                            const newState = [...prevState];

                            newState.splice(index, 1);

                            return newState;
                        });
                    }
                };

                const items: ItemType[] = [
                    {
                        icon: <CheckCircleOutlined />,
                        key: `${record.key}-accept-all`,
                        label: t('Accept all'),
                        onClick: (e: any) =>
                            respond(e, ProviderRequestTransition.ACCEPT, () => {
                                notification.success({
                                    message: t('Order successfully accepted'),
                                });

                                onAccept?.();
                            }),
                    },
                    {
                        icon: <CloseCircleTwoTone twoToneColor="red" />,
                        key: `${record.key}-reject-all`,
                        label: t('Reject all'),
                        onClick: (e: any) => {
                            e.domEvent.stopPropagation();

                            Modal.confirm({
                                title: t('Reject order'),
                                icon: null,
                                content: (
                                    <>
                                        <p>{t('Are you sure you want to reject the entire order?')}</p>
                                        <p>{t('The action cannot be reversed once done.')}</p>
                                    </>
                                ),
                                okText: t('Reject order'),
                                cancelText: t('Cancel'),
                                centered: true,
                                onOk: () =>
                                    respond(e, ProviderRequestTransition.REJECT, () => {
                                        notification.success({
                                            message: t('Order successfully rejected'),
                                        });
                                    }),
                            });
                        },
                    },
                ];

                return renderDropdownMenu(record.key, items);
            }

            return;
        }

        const { provider_requests } = record.request;
        const providerRequest = provider_requests[0];

        const getPossibleTransitions = () => providerRequest?.possibleTransitions as ProviderRequestTransition[];

        return (
            <ProviderRequestActionButtons
                onAccept={onAccept}
                possibleTransitions={getPossibleTransitions()}
                providerRequest={providerRequest}
                request={record.request}
                isIconButton={true}
                updateRequest={(request) => updateRequests([request])}
                hideProgress
            />
        );
    };

    const renderClientActionButton = (record: DataSource) => {
        const actions = {
            addNewRequest: {
                icon: <PlusOutlined />,
                key: `${record.key}-add-request`,
                label: t('Add new request'),
                onClick({ domEvent }: any) {
                    domEvent.stopPropagation();

                    goToCreatePage(record.id, true);
                },
            },
            cancel: {
                icon: <CloseCircleTwoTone twoToneColor="red" />,
                key: `${record.key}-cancel`,
                label: t(record.children ? 'Cancel order' : 'Cancel request'),
                onClick({ domEvent }: any) {
                    domEvent.stopPropagation();

                    const title = t(record.children ? 'Cancel order' : 'Cancel request');

                    Modal.confirm({
                        title,
                        icon: null,
                        content: (
                            <>
                                <p>
                                    {t(
                                        `Are you sure you want to cancel this ${record.children ? 'order' : 'request'}?`
                                    )}
                                </p>
                                <p>{t('Action cannot be reversed once done.')}</p>
                            </>
                        ),
                        okText: title,
                        cancelText: t('Dismiss'),
                        centered: true,
                        onOk: async () => {
                            const order = orders.find(
                                ({ id }) => id === (record.request ? record.request.order_id : record.id)
                            );

                            if (!order) {
                                return;
                            }

                            const deleteRequestOnly = record.request && order.requests.length > 1;

                            if (deleteRequestOnly) {
                                await Backend.delete(`/staffing/requests/${record.request!.id}`);

                                const requestIndex = order.requests.findIndex(({ id }) => id === record.request!.id);

                                order.requests.splice(requestIndex, 1);
                                updateOrder(order);
                            } else {
                                await Backend.delete(`/staffing/orders/${record.id}`);

                                removeOrder(order);
                            }
                        },
                    });
                },
            },
            downloadRequest: {
                icon: <DownloadOutlined />,
                key: `${record.key}-download-excel`,
                label: t('Download excel'),
                async onClick({ domEvent }: any) {
                    domEvent.stopPropagation();

                    await downloadExcel(
                        orders.find(({ id }) => id === record.id) as Order,
                        localeContext,
                        record.request as Request
                    );
                },
            },
            editDraft: (
                <Button
                    icon={<EditOutlined />}
                    style={{ marginRight: '20px' }}
                    size={'small'}
                    onClick={(e?: React.MouseEvent) => {
                        e?.stopPropagation();

                        goToCreatePage(record.id, false, record.request?.id);
                    }}
                >
                    {t('Edit')}
                </Button>
            ),
            editRequest: {
                icon: <EditOutlined />,
                key: `${record.key}-edit-request`,
                label: t('Edit request'),
                onClick({ domEvent }: any) {
                    domEvent.stopPropagation();

                    if (!record.request) {
                        return;
                    }

                    goToRequestPage(record.request.id, true);
                },
            },
            moveToHistory: {
                icon: <FileArchive />,
                key: `${record.key}-move-to-history`,
                label: t('Move to History'),
                onClick({ domEvent }: any) {
                    domEvent.stopPropagation();

                    Modal.confirm({
                        title: null,
                        icon: null,
                        content: (
                            <>
                                <p>{t('Are you sure you want to move this request to History?')}</p>
                                <p style={{ color: 'darkgrey' }}>{t('Only finished shifts will be affected.')}</p>
                            </>
                        ),
                        okText: t('Yes'),
                        cancelText: t('No'),
                        centered: true,
                        onOk: () => moveRecordJobsToHistory(record),
                    });
                },
            },
        };

        const items: ItemType[] = [];
        let editDraft: any = null;

        if (activeTab === AvailableTabs.DRAFT) {
            record.children ? items.push(actions.addNewRequest) : (editDraft = actions.editDraft);

            items.push(actions.downloadRequest, actions.cancel);
        } else if (
            [AvailableTabs.PENDING, AvailableTabs.ACTIVE].includes(activeTab) &&
            // has none canceled jobs
            record.request?.jobs?.some(({ state }) => state !== JobState.CANCELED)
        ) {
            items.push(actions.editRequest);

            if (record.request) {
                items.push(actions.moveToHistory);
            }

            items.push(actions.downloadRequest);
        } else {
            items.push(actions.downloadRequest);

            if (activeTab !== AvailableTabs.HISTORY && record.request) {
                items.push(actions.moveToHistory);
            }
        }

        return items.length || editDraft ? renderActionColumn(record.key, items, editDraft) : null;
    };

    const renderActionColumn = (key: any, items: ItemType[], editDraft = null) => (
        <>
            {editDraft}
            {items.length ? renderDropdownMenu(key, items) : null}
        </>
    );

    const renderDropdownMenu = (key: any, items: ItemType[]) => (
        <Dropdown key={key} menu={{ items }} placement="bottomRight">
            <MoreOutlined onClick={(e) => e.stopPropagation()} />
        </Dropdown>
    );

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        const customFilter: GenericObject = {};

        for (const key in filters) {
            if (!filters[key]) {
                continue;
            }

            if (key === 'startEnd') {
                customFilter['startDate'] = filters[key][0][0];
                customFilter['endDate'] = filters[key][0][1];

                continue;
            }

            customFilter[key] = filters[key][0];
        }

        setFilters(filters);

        setTableParams({
            pagination,
            filters: customFilter,
            sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
            sortField: Array.isArray(sorter) ? undefined : sorter.field,
        } as any);
    };

    const columns: any[] = [
        {
            dataIndex: 'profession',
            sorter: true,
            title: t('Profession'),
            ...getColumnSearchProps('profession', true),
        },
        {
            dataIndex: 'startEnd',
            sorter: true,
            title: t('Start - End'),
            width: 200,
            ...getColumnDateRangePickerProps('startEnd'),
        },
        {
            title: t('Location'),
            dataIndex: 'location',
            ...getColumnSearchProps('address', true),
            width: 200,
            ellipsis: true,
        },
    ];

    if (window.innerWidth < 1650 && columns.length > 0) {
        columns[0].width = 200;
    }

    const createdAtColumn = {
        ...getColumnDatePickerProps('createdAt', 'DD.MM.YYYY', true),
        dataIndex: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: true,
        title: t('Created on'),
        width: 145,
    };

    if (isProvider) {
        columns.push(
            {
                title: t('Client'),
                dataIndex: 'client',
                filters: providerFilterOptions,
                filterMultiple: true,
                filterSearch: true,
                ellipsis: true,
                width: 200,
            },
            createdAtColumn
        );
    } else {
        columns.push(createdAtColumn);
    }

    if (activeTab === AvailableTabs.HISTORY) {
        columns.push({
            title: '',
            dataIndex: 'state',
            width: 200,
            render: (state: string) => {
                let badgeStyle = {};
                let stateBadge;
                if (state === RequestState.CANCELED) {
                    badgeStyle = { width: '8px' };
                    stateBadge = <Badge status="default" style={badgeStyle} />;
                } else if (state === RequestState.REJECTED) {
                    badgeStyle = { width: '8px' };
                    stateBadge = <Badge status="error" style={badgeStyle} />;
                }

                const stateName = isProvider
                    ? state === RequestState.CANCELED
                        ? t('Canceled')
                        : state === RequestState.REJECTED
                        ? t('Rejected')
                        : null
                    : state === RequestState.CANCELED
                    ? t('Canceled')
                    : null;

                return (
                    <Space>
                        {stateBadge && stateName && (
                            <>
                                {stateBadge}
                                {stateName}
                            </>
                        )}
                    </Space>
                );
            },
        });
    }

    if (showProgress) {
        columns.push({
            title: t('Progress'),
            onCell: (record: DataSource) => ({
                onClick: (e: any) => {
                    if (isProvider) {
                        return;
                    }

                    e.stopPropagation();

                    setPreviewOrder(orders.find(({ id }) => id === record.id));
                    setPreviewRequestId(record.request?.id ?? undefined);
                },
                style: { cursor: isProvider ? 'unset' : 'pointer' },
            }),
            dataIndex: 'progress',
            width: 200,
        });
    }

    if (isProvider && activeTab !== AvailableTabs.HISTORY) {
        let width = actionColumnWidth;

        if (!width) {
            width = 140;
        }

        columns.push({
            align: 'right',
            className: 'no-wrap',
            render: (_: any, record: DataSource) => renderProviderActionButton(record),
            dataIndex: 'actions',
            width,
        });
    }

    if (!isProvider) {
        let width = 50;

        if (activeTab === AvailableTabs.DRAFT) {
            width = 140;
        }

        columns.push({
            align: 'right',
            className: 'no-wrap',
            render: (_: any, record: DataSource) => renderClientActionButton(record),
            dataIndex: 'actions',
            width,
        });
    }

    type ExpandIconParam = {
        expanded: boolean;
        onExpand: (record: DataSource, e: MouseEvent<HTMLSpanElement>) => any;
        record: DataSource;
    };

    const expandable = {
        rowExpandable: (record: DataSource) => !!record.children,
        expandRowByClick: true,
        expandedRowRender: () => null,
        expandIcon: ({ expanded, onExpand, record }: ExpandIconParam) => {
            if (!record.children) {
                return !record.partOfOrder && <FileIcon color="#0096E1" width={13} height={13} />;
            }

            return expanded ? (
                <CaretDownOutlined style={{ color: 'rgba(0,0,0,.45)' }} onClick={(e) => onExpand(record, e)} />
            ) : (
                <CaretRightOutlined style={{ color: 'rgba(0,0,0,.45)' }} onClick={(e) => onExpand(record, e)} />
            );
        },
    };

    return (
        <>
            {previewOrder && (
                <ProgressModal
                    close={() => {
                        setPreviewOrder(undefined);
                        setPreviewRequestId(undefined);
                    }}
                    order={previewOrder}
                    requestId={previewRequestId}
                    width={700}
                />
            )}

            <Table
                columns={columns}
                dataSource={dataSource}
                expandable={expandable}
                loading={isLoadingOrders}
                onChange={handleTableChange}
                onRow={({ request }: DataSource) => ({
                    onClick: () => {
                        if (!request) {
                            return;
                        }

                        goToRequestPage(request.id);
                    },
                })}
                pagination={tableParams.pagination}
                rowKey="key"
            />
        </>
    );
};
