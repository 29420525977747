import 'shared/Requests/Request/Request.scss';

import { Button, Modal, notification, Space, Tag, Typography } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useAccount } from 'lib/Account';
import { useLocalization } from 'lib/Localization';
import { Backend } from 'lib/Http/Backend';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoaderSkeleton } from 'shared/AntDesignUtils/Loaders';
import { RequestJobsTable } from 'shared/Requests/Request/RequestJobsTable';
import { useErrorHandler } from 'lib/ErrorHandling';
import { RequestHeaderTag } from 'pages/Client/Requests/Request/RequestHeaderTag';
import { RequestActionButtons } from 'pages/Client/Requests/Request/RequestActionButtons';
import { ProviderRequestHeaderTag } from 'pages/Provider/Requests/Request/ProviderRequestHeaderTag';
import {
    JobToSaveType,
    ProviderRequestActionButtons
} from 'pages/Provider/Requests/Request/ProviderRequestActionButtons';
import { Address, Department, Job, Profession, Provider, ProviderRequest, Request as RequestModel } from 'types/models';
import { FormattedJob, FormRequest, ProviderRequestTransition, RequestState, RequestTransition } from 'types/staffing';
import { formatJobs } from '../helpers';
import { userIsClient, userIsProvider } from 'lib/Helpers/UserHelper';
import { useSearchParams } from 'lib/Helpers/SearchParams';
import { useNavigate } from 'react-router-dom-v5-compat';
import { localizeProfession } from 'lib/Helpers/ProfessionHelper';
import { ShiftFormModal } from 'shared/Requests/Request/ShiftFormModal';
import { DownloadOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { RequestCreationLocationFormsDrawer } from 'pages/Client/Requests/Create/RequestCreationLocationFormsDrawer';
import { RequestLocation } from 'shared/Requests/Request/RequestLocation';
import { requestToFormRequest } from 'lib/Request/utils';
import { JobApplicantState } from 'types/staffing/JobApplicantStateMachine';
import { UsersIcon } from 'shared/icons/UsersIcon';
import { useRequestActions } from 'lib/hooks/useRequestActions';
import { OrderFormRequest } from 'pages/Client/Requests/Create/RequestCreation';
import { QuotaModal } from 'shared/Requests/Request/QuotaModal/QuotaModal';

const { Title, Text } = Typography;

export const Request = () => {
    const { t, locale } = useLocalization();
    const handleError = useErrorHandler();
    const navigate = useNavigate();
    const { accountUser: user } = useAccount();
    const isProvider = userIsProvider(user);
    const isClient = userIsClient(user);
    const { id } = useParams<{ id: string }>();

    const { getProviderWorkerQuota } = useRequestActions();

    const requestsURL = `/staffing/requests/${id}`;

    const [request, setRequest] = useState<RequestModel>({} as RequestModel);
    const [providerRequest, setProviderRequest] = useState<ProviderRequest>({} as ProviderRequest);
    const [loadingRequest, setLoadingRequest] = useState(true);
    const [jobsToUpdate, setJobsToUpdate] = useState<FormattedJob[]>([]);
    const [jobs, setJobs] = useState<FormattedJob[]>([]);
    const [editRequest, setEditRequest] = useState(false);
    const [jobsToCancel, setJobsToCancel] = useState<number[]>([]);
    const [editProposal, setEditProposal] = useState(false);
    const [formRequest, setFormRequest] = useState<FormRequest>();
    const [jobEditing, setJobEditing] = useState<FormattedJob>();
    const [openShiftFormModal, setOpenShiftFormModal] = useState(false);
    const [showAllProviders, setShowAllProviders] = useState(false);
    const [historical, setHistorical] = useState(false);
    const [isAddressFormOpen, setIsAddressFormOpen] = useState(false);
    const [isDepartmentFormOpen, setIsDepartmentFormOpen] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [addresses, setAddresses] = useState<any[]>([]);
    const [loadingLocations, setLoadingLocations] = useState(true);
    const [requestAdress, setRequestAdress] = useState<number>();
    const [jobsToSave, setJobsToSave] = useState<JobToSaveType[]>([]);
    const [savedJobs, setSavedJobs] = useState<JobToSaveType[]>([]);
    const [openQuotaModal, setOpenQuotaModal] = useState<boolean>(false);
    const [loadingProviders, setLoadingProviders] = useState<boolean>(false);
    const [providers, setProviders] = useState<Provider[]>([]);

    useSearchParams<any>({
        edit: () => startEditing(),
        history: () => setHistorical(true),
    });

    const formatAndSetJobs = (jobs: Job[]) => setJobs(formatJobs(jobs, isClient));

    useEffect(() => {
        setJobsToSave(
            jobs.map((job) => {
                if (
                    job.workerId &&
                    !job.applicants?.find(
                        ({ worker_id, state }) => worker_id === job.workerId && [
                            JobApplicantState.OPEN,
                            JobApplicantState.PROPOSED,
                            JobApplicantState.ACCEPTED,
                        ].includes(state)
                    )
            ) {
                    return {
                        worker_id: job.workerId,
                        job_id: job.id,
                    };
                }
            })
            .filter(Boolean) as JobToSaveType[]
        );
        setSavedJobs(
            jobs.map((job) => {
                if (
                    job.workerId &&
                    job.applicants?.find(
                        ({ worker_id, state }) => worker_id === job.workerId && [
                            JobApplicantState.OPEN,
                            JobApplicantState.PROPOSED,
                            JobApplicantState.ACCEPTED,
                        ].includes(state)
                    )
                ) {
                    return {
                        worker_id: job.workerId,
                        job_id: job.id,
                    };
                }
            })
                .filter(Boolean) as JobToSaveType[]
        );
    }, [jobs]);

    useEffect(() => {
        const loadProviders = async (request: FormRequest) => {
            try {
                setLoadingProviders(true);

                const response = await Backend.get('/data-management/providers?relations=professions');
                const providers = (response.data.providers || [])
                    .filter(
                        (provider: Provider) => provider.professions.some(
                            ({ id }: Profession) => id === request.profession_id
                        )
                    )
                    .sort(
                        (p1: Provider, p2: Provider) => p1.company_name.localeCompare(p2.company_name)
                    );

                setProviders(providers);
            } catch (error) {
                handleError(error);
            } finally {
                setLoadingProviders(false);
            }
        };

        const loadRequestData = async () => {
            try {
                setLoadingRequest(true);

                const response = await Backend.get(requestsURL);
                const { request } = response.data;

                setRequest(request);

                if (isProvider && request.provider_requests.length) {
                    setProviderRequest(request.provider_requests[0]);
                }

                return request;
            } catch (error) {
                handleError(error);
            } finally {
                setLoadingRequest(false);
            }
        };

        const load = async () => {
            const request = await loadRequestData();

            if (isProvider) {
                return;
            }

            await loadProviders(request);
        };

        load();

    }, [requestsURL, handleError]);

    useEffect(() => {
        if (!request.jobs) {
            return;
        }

        const jobsToFormat: Job[] = [];

        if (historical) {
            if ([RequestState.CANCELED, RequestState.EXPIRED, RequestState.REJECTED].includes(request.state as any)) {
                jobsToFormat.push(...request.jobs);
            } else {
                jobsToFormat.push(
                    ...request.jobs.filter(
                        (job) => (isClient && job.is_client_archived) || (isProvider && job.is_provider_archived)
                    )
                );
            }
        } else {
            jobsToFormat.push(
                ...request.jobs.filter(
                    (job) => (isClient && !job.is_client_archived) || (isProvider && !job.is_provider_archived)
                )
            );
        }

        formatAndSetJobs(jobsToFormat);
    }, [request]);

    const loadLocations = useCallback(async () => {
        try {
            setLoadingLocations(true);

            const addressesResponse = await Backend.get('/data-management/addresses?collect=departmentIds');
            const addressesData = addressesResponse.data.addresses || [];

            setAddresses(
                addressesData
                    .sort((a1: Address, a2: Address) => a1.address.localeCompare(a2.address))
                    .map((address: Address) => {
                        address.department_ids = addressesResponse.data.collections?.departmentIds?.[address.id] || [];

                        return address;
                    })
            );
        } catch (error) {
            handleError(error);
        } finally {
            setLoadingLocations(false);
        }
    }, [handleError]);

    const startEditing = () => {
        loadLocations();

        if (request.jobs) {
            setFormRequest(requestToFormRequest(request));
        }

        setRequestAdress(requestAdress ?? request.address_id);
        setEditRequest(true);
    };

    const canceledDate = new Date(request.updated_at ?? '').toLocaleDateString(locale.shortCode, {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    });

    const renderCanceledByInfoText = request.canceled_by && (
        <span className="pull-right">
            {t('Canceled on')} {canceledDate} {t('by')}
            &nbsp;
            <Text strong>
                {request.canceled_by?.first_name} {request.canceled_by?.last_name}
            </Text>
            &nbsp;
        </span>
    );

    const renderRequestHeader = isProvider ? (
        <ProviderRequestHeaderTag providerRequestState={providerRequest.state} requestState={request.state} />
    ) : (
        <RequestHeaderTag requestState={request.state} />
    );

    const cancelJobEditing = () => {
        setJobEditing(undefined);
        setOpenShiftFormModal(false);
    };

    const handleUpdateRequest = async () => {
        try {
            await Backend.put(`staffing/requests/${request.id}/location`, {
                id: request.id,
                is_update_location: true,
                address_id: requestAdress,
            });

            request.address = addresses.find((address) => address.id === requestAdress);

            return true;
        } catch (error) {
            handleError(error);
            return false;
        }
    };

    const handleUpdateJobs = async (_jobsToUpdate: FormattedJob[] = []) => {
        try {
            if (!jobsToUpdate.length && !_jobsToUpdate.length) {
                return true;
            }

            const requestJobs = [...jobsToUpdate, ..._jobsToUpdate]
                .map((job) => {
                    const originalJob = jobs.find((j) => j.id === job.id) as FormattedJob;

                    if (
                        originalJob.time !== job.time ||
                        originalJob.date !== job.date ||
                        originalJob.start_time !== job.start_time ||
                        originalJob.shiftDuration !== job.shiftDuration ||
                        originalJob.breakStartTime !== job.breakStartTime ||
                        originalJob.breakDuration !== job.breakDuration
                    ) {
                        return {
                            ...job,
                            prevTime: originalJob!.time,
                            prevDate: originalJob!.date,
                            prevStartTime: originalJob!.start_time,
                            prevShiftDuration: originalJob!.shiftDuration,
                            prevBreakStartTime: originalJob!.breakStartTime,
                            prevBreakDuration: originalJob!.breakDuration,
                            prevComment: originalJob!.comment,
                        };
                    }

                    return job;
                });

            const response = await Backend.put(`staffing/requests/${request.id}/jobs`, {
                jobs: requestJobs,
            });

            if (response.status === Backend.responseStatus.HTTP_OK) {
                notification.success({
                    message: t(`Job successfully updated`),
                });

                const jobs = [...request.jobs];

                response.data.forEach((job: Job) => {
                    const index = jobs.findIndex(({ id }) => id === job.id);

                    if (index === -1) {
                        return;
                    }

                    jobs.splice(index, 1, {
                        ...jobs[index],
                        ...job,
                    });
                });

                setRequest((prevState) => ({
                    ...prevState,
                    jobs,
                }));

                setJobsToUpdate([]);
                setEditRequest(false);
                setJobEditing(undefined);
                setOpenShiftFormModal(false);
            }

            return true;
        } catch (error) {
            handleError(error);

            return false;
        }
    };

    const updateJobsToSave = (jobsToSave: JobToSaveType[]) => {
        setJobsToSave(jobsToSave);
        setRequest(request);
        formatAndSetJobs(request.jobs);
    };

    const getActionButtons = () => {
        if (!isProvider) {
            return (
                <RequestActionButtons
                    editRequest={editRequest}
                    jobs={jobs}
                    jobsToCancel={jobsToCancel}
                    handleUpdateJobs={handleUpdateJobs}
                    handleUpdateRequest={handleUpdateRequest}
                    historical={historical}
                    possibleTransitions={request.possibleTransitions as RequestTransition[]}
                    request={request}
                    requestsURL={requestsURL}
                    setJobsToCancel={setJobsToCancel}
                    setJobsToUpdate={setJobsToUpdate}
                    setRequest={setRequest}
                    startEditing={startEditing}
                    stopEditing={() => setEditRequest(false)}
                />
            );
        }

        if (isProvider && [RequestState.PENDING, RequestState.POSTED, RequestState.FULFILLED].includes(request.state)) {
            return (
                <ProviderRequestActionButtons
                    editProposal={editProposal}
                    jobsToSave={jobsToSave}
                    historical={historical}
                    possibleTransitions={providerRequest.possibleTransitions as ProviderRequestTransition[]}
                    providerRequest={providerRequest}
                    request={request}
                    savedJobs={savedJobs}
                    setEditProposal={setEditProposal}
                    updateJobsToSave={updateJobsToSave}
                    isIconButton={false}
                    setRequest={setRequest}
                    updateRequest={(request: RequestModel) => {
                        setRequest(request);

                        if (request.provider_requests.length) {
                            setProviderRequest(request.provider_requests[0]);
                        }
                    }}
                />
            );
        }
    };

    const actionConfirm = (title: string, onOk: () => void) =>
        Modal.confirm({
            title: t(title),
            icon: null,
            content: (
                <>
                    <p>{t('The progress you made will be lost and shifts might be filled by other providers.')}</p>
                </>
            ),
            okText: t('Yes'),
            cancelText: t('Cancel'),
            centered: true,
            onOk,
        });

    const handleBackButtonClick = () => {
        if (isProvider && editProposal) {
            actionConfirm('Are you sure you want to leave the page?', () => navigate(-1));
        } else {
            navigate(-1);
        }
    };

    const getProviderList = () => {
        const providerRequests = showAllProviders ?
            request.provider_requests :
            request.provider_requests.slice(0, 40);

        return providerRequests.map(({ provider }) => (
            <Tag style={{ fontWeight: 'bold' }}>
                <Space>
                    {provider.company_name}
                    <UsersIcon />
                    {getProviderWorkerQuota(request as unknown as OrderFormRequest, provider.id)}
                </Space>
            </Tag>
        ));
    };

    const closeLocationForms = () => {
        setIsAddressFormOpen(false);
        setIsDepartmentFormOpen(false);
    };

    const onOpenAddressForm = () => {
        closeLocationForms();
        setIsAddressFormOpen(true);
    };

    const onAddressFormSaved = async (address?: Address) => {
        await loadLocations();
        closeLocationForms();

        if (address) {
            request.address_id = address?.id;
            setRequestAdress(address?.id);
        }
    };

    const onDepartmentFormSaved = async (department?: Department) => {};

    return loadingRequest ? (
        <LoaderSkeleton size={2} />
    ) : (
        <>
            <PageHeader
                title={localizeProfession(request.profession, locale).name}
                onBack={handleBackButtonClick}
                ghost={false}
                className="request-page-header"
                tags={[renderRequestHeader, renderCanceledByInfoText]}
                extra={getActionButtons()}
            >
                <div className="request-header-info">
                    <table className="request-header-table">
                        {isClient && (
                            <tr>
                                <td style={{ verticalAlign: 'middle' }}>{t('Providers')}:</td>
                                <td>
                                    <p className="request-header-table-providers">
                                        {getProviderList()}

                                        {request.provider_requests.length > 40 && (
                                            <Button
                                                className="request-header-table-providers-button"
                                                type="link"
                                                onClick={() => setShowAllProviders(!showAllProviders)}
                                            >
                                                {t(showAllProviders ? 'see less' : 'see all')}
                                            </Button>
                                        )}

                                        {editRequest && (
                                            <Button icon={<EditOutlined />} onClick={() => setOpenQuotaModal(true)}>
                                                {t('Edit quota')}
                                            </Button>
                                        )}
                                    </p>
                                </td>
                            </tr>
                        )}
                        {isProvider && (
                            <tr>
                                <td>{t('Client:')}</td>
                                <td>{request.client.company_name}</td>
                            </tr>
                        )}
                        {!editRequest && (
                            <tr>
                                <td>{t('Location:')}</td>
                                <td>{request.address?.name}</td>
                            </tr>
                        )}
                        {!editRequest && (
                            <tr>
                                <td>{t('Address:')}</td>
                                <td>
                                    {request.address?.address}
                                    {(request.address?.zip_code || request.address?.city) && ', '}
                                    {request.address?.zip_code} {request.address?.city}
                                </td>
                            </tr>
                        )}
                        {!editRequest && (
                            <tr>
                                <td>{t('Department:')}</td>
                                <td>{request.department?.name}</td>
                            </tr>
                        )}
                        {!editRequest && (
                            <tr>
                                {request.notes ? (
                                    <>
                                        <td>{t('Notes:')}</td>
                                        <td>{request.notes}</td>
                                    </>
                                ) : null}
                            </tr>
                        )}
                        {!editRequest && !!request.documents?.length && (
                            <tr>
                                <td valign="top">{t('Documents')}:</td>
                                <td>
                                    <Space wrap>
                                        {request.documents.map((document, index) => (
                                            <a href={document.url} target="_blank">
                                                <Space style={{ marginRight: '15px' }}>
                                                    {document.name}
                                                    <DownloadOutlined />
                                                </Space>
                                            </a>
                                        ))}
                                    </Space>
                                </td>
                            </tr>
                        )}
                    </table>

                    {/* <Title level={5}>{request.client.company_name}</Title> */}
                </div>
            </PageHeader>

            {isClient && editRequest && (
                <>
                    <RequestLocation
                        onCreateAddressHandler={onOpenAddressForm}
                        addresses={addresses}
                        recentAddresses={[]}
                        loadingLocations={loadingLocations}
                        requestAdress={requestAdress}
                        setRequestAdress={setRequestAdress}
                    />

                    <RequestCreationLocationFormsDrawer
                        departments={departments}
                        onSaveAddressHandler={onAddressFormSaved}
                        onSaveDepartmentHandler={onDepartmentFormSaved}
                        onFormClose={closeLocationForms}
                        isAddressFormOpen={isAddressFormOpen}
                        isDepartmentFormOpen={isDepartmentFormOpen}
                    />

                    <div className="text-right">
                        <Button icon={<PlusOutlined />} type="link" onClick={() => setOpenShiftFormModal(true)}>
                            {t('Add shifts')}
                        </Button>
                    </div>
                </>
            )}

            <RequestJobsTable
                editProposal={editProposal}
                editRequest={editRequest}
                jobs={jobs}
                jobsToCancel={jobsToCancel}
                jobsToUpdate={jobsToUpdate}
                historical={historical}
                providerRequest={providerRequest}
                request={request}
                setJobs={setJobs}
                setJobsToCancel={setJobsToCancel}
                setJobToEdit={(job) => {
                    setJobEditing(structuredClone(job));
                    setOpenShiftFormModal(true);
                }}
                setRequest={setRequest}
            />

            {openShiftFormModal && (
                <ShiftFormModal
                    departmentId={request.department_id}
                    job={jobEditing}
                    jobsToUpdate={jobsToUpdate}
                    onCancel={cancelJobEditing}
                    open={true}
                    providers={providers}
                    request={request}
                    setJobsToUpdate={setJobsToUpdate}
                    setOpenModal={setOpenShiftFormModal}
                    setRequest={setRequest}
                />
            )}

            {openQuotaModal && (
                <QuotaModal
                    open={openQuotaModal}
                    providers={providers}
                    request={request}
                    handleUpdateJobs={handleUpdateJobs}
                    setOpenModal={setOpenQuotaModal}
                    setRequest={setRequest}
                />
            )}
        </>
    );
};
