import type { MenuProps } from 'antd';
import { Avatar, Drawer, Dropdown, Layout, Menu, Tabs, Tag } from 'antd';
import { locales, useLocalization } from 'lib/Localization';
import { Authentication, useAccount } from 'lib/Account';
import { ReactText, useState } from 'react';
import {
    BellFilled,
    DownOutlined,
    LoadingOutlined,
    LogoutOutlined,
    SettingOutlined,
    UpOutlined,
    UserOutlined,
    UserSwitchOutlined
} from '@ant-design/icons';
import _ from 'lodash';
import { ApiTokens, ChangePasswordForm, UserProfileForm } from 'shared/Account/UserProfile';
import { MenuInfo } from 'rc-menu/lib/interface';
import { AppSection } from 'types';
import { Logo } from './logo';
import { userIsAdmin, userIsProvider } from 'lib/Helpers/UserHelper';
import { NotificationsPopover } from 'shared/Notifications/NotificationsPopover';
import { Link, useNavigate } from 'react-router-dom-v5-compat';

type Props = {
    section: AppSection;
};

/**
 * Generic header component used across sub apps.
 */
export const Header = ({ section }: Props) => {
    const { t } = useLocalization();
    const localizationContext = useLocalization();
    const { accountUser: user, setAccountUser: setUser } = useAccount();
    const navigate = useNavigate();
    const [isEditUserProfileOpen, setIsEditUserProfileOpen] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [openKeys, setOpenKeys] = useState<ReactText[]>([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const [langMenuOpen, setLangMenuOpen] = useState(false);

    /**
     * Logout authenticated user.
     *
     * @return {Promise<void>}
     */
    const logoutHandler = async () => {
        setIsLoggingOut(true);
        await Authentication.logout();
        setUser(Authentication.guestUser);
        navigate('/login');
    };

    /**
     * Handle menu click
     *
     * @param event
     */
    const handleMenuClick = (event: MenuInfo) => {
        if (event.key !== 'logout') {
            setMenuOpen(false);
        }
        setOpenKeys([]);
    };

    const userMenuItems: MenuProps['items'] = [
        {
            key: 'account',
            icon: <UserOutlined/>,
            label: t('My Account'),
            onClick: () => setIsEditUserProfileOpen(true),
        },
    ];

    let userType = 'client';

    if (userIsAdmin(user)) {
        userType = 'admin';
    } else if (userIsProvider(user)) {
        userType = 'provider';
    }

    if (!userIsAdmin(user)) {
        userMenuItems.push({
            key: 'settings',
            icon: <SettingOutlined/>,
            label: <Link to={`/${userType}/settings`}>{t('Settings')}</Link>,
        });
    }

    userMenuItems.push({
        key: 'logout',
        icon: isLoggingOut ? <LoadingOutlined spin/> : <LogoutOutlined/>,
        label: isLoggingOut ? t('Logging out...') : t('Logout'),
        onClick: logoutHandler,
    });

    if (user.types.length > 1) {
        userMenuItems.unshift({
            key: 'configuration',
            icon: <UserSwitchOutlined />,
            label: _.capitalize(t(section.identifier)),
            children: user.types.map((role) => ({
                key: role,
                label: <Link to={`/${role}`}>{_.capitalize(t(role))}</Link>,
            })),
        });
    }

    const userMenu = (
        <Menu
            onClick={handleMenuClick}
            openKeys={openKeys as string[]}
            onOpenChange={(newOpenKeys) => setOpenKeys(newOpenKeys)}
            items={userMenuItems}
        />
    );

    const langMenuItems: MenuProps['items'] = locales.map((locale) => ({
        key: locale.shortCode,
        label: locale.name,
        onClick: () => localizationContext.changeLocale(locale.shortCode),
    }));

    const langMenu = (
        <Menu
            onClick={() => setLangMenuOpen(false)}
            mode="vertical"
            overflowedIndicator={localizationContext.locale.shortCode}
            items={langMenuItems}
        />
    );

    const profile = user.profile;
    // Display email if user doesn't have a profile for the current sub app.
    let username = user.username;
    if (profile.first_name || profile.last_name) {
        username = `${profile.first_name} ${profile.last_name}`;
    }

    const tabItems = [
        {
            label: t('Profile'),
            key: '1',
            children: <UserProfileForm setIsEditUserProfileOpen={setIsEditUserProfileOpen} />,
        },
        {
            label: t('Password'),
            key: '2',
            children: <ChangePasswordForm setIsEditUserProfileOpen={setIsEditUserProfileOpen} />,
        },
        {
            label: t('API tokens'),
            key: '3',
            children: <ApiTokens />,
        },
    ];

    return (
        <Layout.Header className="main-header mdc-elevation--z4">
            <div className="main-logo">
                <Link to={`/${section.identifier}`}>
                    <Logo />
                </Link>
            </div>
            <div>
                <h1 className="title">{t('%{user} portal', {user: t(section.identifier)})}</h1>
            </div>

            { !userIsAdmin(user) && (
                <NotificationsPopover
                    className="header-menu-right push"
                    placement="bottomRight"
                    trigger="click"
                >
                    <Tag
                        color="#1677ff"
                        style={ {
                        padding: '5px 15px',
                        borderRadius: '16px',
                        cursor: 'pointer',
                        marginRight: '30px'
                    } }
                    >
                        <BellFilled
                            color="#fff"
                            style={ { marginRight: user.unreadNotificationsCount ? '5px' : '0' } } />
                        { user.unreadNotificationsCount || '' }
                    </Tag>
                </NotificationsPopover>
            ) }

            <nav className={userIsAdmin(user) ? 'header-menu-right push' : 'header-menu-right'}>
                <Dropdown overlay={userMenu} onOpenChange={setMenuOpen} open={menuOpen}>
                    <div>
                        <span className="username">{username}</span>
                        <Avatar className="avatar" size="large" icon={<UserOutlined />} />
                    </div>
                </Dropdown>
            </nav>
            <div className="lang-menu">
                <Dropdown overlay={langMenu} onOpenChange={setLangMenuOpen}>
                    <div>
                        <span className="lang-title">
                            {localizationContext.locale.shortCode}&nbsp;
                            {langMenuOpen ? <UpOutlined /> : <DownOutlined />}
                        </span>
                    </div>
                </Dropdown>
            </div>
            <Drawer
                title={t('My Account')}
                width={500}
                onClose={() => setIsEditUserProfileOpen(false)}
                open={isEditUserProfileOpen}
            >
                <Tabs items={tabItems} />
            </Drawer>
        </Layout.Header>
    );
};
