import { Table, Tag, Tooltip } from 'antd';
import { useLocalization } from 'lib/Localization';
import { getColumnSearchProps, sortColumn, getDefaultPaginationProp } from 'lib/Helpers/TableHelper';
import moment from 'moment';
import { User } from 'types/models';
import { ColumnsType } from 'antd/lib/table';
import { useCollections } from 'lib/Contexts/Collections';

type Props = {
    users: User[];
    loading: boolean;
};

/**
 * Table to display all users.
 *
 * @param users
 * @param loading
 * @return {*}
 */

export const UsersTable = ({ users, loading }: Props) => {
    const { t } = useLocalization();
    const [collections] = useCollections();

    const roleOptions = collections.roles.map((role) => ({
        value: role.id,
        text: role.name,
    }));

    /**
     * Render cell for user's name.
     *
     * @param user
     */
    const formatName = (user: User) => {
        return `${user.first_name} ${user.last_name}`;
    };

    /**
     * Render cell for email of contact.
     *
     * @param email {string}
     * @return {*}
     */
    const renderEmailCell = (email: string) => (
        <a href={`mailto:${email}`} target="blank">
            {email}
        </a>
    );

    /**
     * Render cell for user's organization.
     *
     * @param user
     */
    const formatOrganizationName = (user: User) => {
        const userOrganization = user.agent_profile?.provider || user.contact_profile?.client;
        return userOrganization?.company_name ?? '';
    };

    /**
     * Render an in-line view for user's roles.
     *
     * @param user { object }
     * @return {*}
     */
    const renderRolesCell = (user: User) => user.roles?.map((role) => <Tag key={role.id}>{role.name}</Tag>);

    /**
     * Render cell for user's last login.
     *
     * @param user { object }
     * @return {*}
     */
    const renderLastLoginCell = (user: User) => (
        <Tooltip
            title={
                <span>Created at: {user.created_at ? moment(user.created_at).format('DD.MM.YYYY HH:mm:ss') : ''}</span>
            }
        >
            {user.last_login_at ? moment(user.last_login_at).format('DD.MM.YYYY HH:mm:ss') : ''}
        </Tooltip>
    );

    /**
     * Filter table by role.
     *
     * @param value
     * @param user
     *
     * @returns {boolean}
     */
    const filterRoles = (value: string | number | boolean, user: User) => {
        for (const role of user.roles || []) {
            if (role.id === value) {
                return true;
            }
        }
        return false;
    };

    const columns: ColumnsType<User> = [
        {
            title: t('Name'),
            dataIndex: 'first_name',
            key: 'first_name',
            sorter: (a, b) => formatName(a).localeCompare(formatName(b)),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('name'),
            render: (first_name, user) => formatName(user),
            onFilter: (value, user): boolean => formatName(user).toLowerCase().includes(value.toString().toLowerCase()),
        },
        {
            title: t('Email'),
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => sortColumn(a, b, 'email'),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('email'),
            render: (email) => renderEmailCell(email),
        },
        {
            title: t('Organization'),
            dataIndex: 'organization',
            key: 'organization',
            sorter: (a, b) => formatOrganizationName(a).localeCompare(formatOrganizationName(b)),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('organization'),
            render: (organization, user) => formatOrganizationName(user),
            onFilter: (value, user): boolean =>
                formatOrganizationName(user).toLowerCase().includes(value.toString().toLowerCase()),
        },
        {
            title: t('Roles'),
            dataIndex: 'roles',
            key: 'roles',
            filters: roleOptions,
            filterMultiple: true,
            filterSearch: true,
            onFilter: (value, user) => filterRoles(value, user),
            sorter: (a, b) => sortColumn(a.roles[0], b.roles[0], 'name'),
            sortDirections: ['ascend', 'descend'],
            render: (roles, user) => renderRolesCell(user),
        },
        {
            title: t('Last login'),
            dataIndex: 'last_login_at',
            key: 'last_login_at',
            sorter: (a, b) => sortColumn(a, b, 'last_login_at'),
            sortDirections: ['ascend', 'descend'],
            render: (last_login_at, user) => renderLastLoginCell(user),
        },
    ];

    return (
        <Table
            rowKey="id"
            columns={columns}
            dataSource={users}
            pagination={getDefaultPaginationProp(users.length)}
            loading={loading}
        />
    );
};
