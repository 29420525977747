import { useEffect, useState } from 'react';
import { t, useLocalization } from 'lib/Localization';
import { Button, Form, Input, notification, Popconfirm, Select } from 'antd';
import { SingleColumnFormLayout } from 'lib/antFormLayouts';
import { Profession, Worker } from 'types/models';
import { FormInstance } from 'antd/lib/form';
import { FloatingLabel } from 'shared/AntDesignUtils/FloatingLabel/FloatingLabel';
import { Backend } from 'lib/Http/Backend';
import { useErrorHandler } from 'lib/ErrorHandling';
import { ProfessionMultiSelect } from 'pages/Provider/Workers/ProfessionMultiSelect';
import TextArea from 'antd/es/input/TextArea';

const { formLayout } = SingleColumnFormLayout;

export enum ShirtSizes {
    WOMAN_XS = 'Woman - XS',
    WOMAN_S = 'Woman - S',
    WOMAN_M = 'Woman - M',
    WOMAN_L = 'Woman - L',
    WOMAN_XL = 'Woman - XL',
    WOMAN_XXL = 'Woman - XXL',
    MAN_S = 'Man - S',
    MAN_M = 'Man - M',
    MAN_L = 'Man - L',
    MAN_XL = 'Man - XL',
    MAN_XXL = 'Man - XXL',
}

const shirtSizeOptions = Object.entries(ShirtSizes).map((value) => {
    return {
        value: value[0],
        label: value[1],
    };
});

export enum TrousersSize {
    WOMAN_34 = 'Woman - 34',
    WOMAN_36 = 'Woman - 36',
    WOMAN_38 = 'Woman - 38',
    WOMAN_40 = 'Woman - 40',
    WOMAN_42 = 'Woman - 42',
    WOMAN_44 = 'Woman - 44',
    WOMAN_46 = 'Woman - 46',
    WOMAN_48 = 'Woman - 48',
    MAN_40 = 'Man - 40',
    MAN_42 = 'Man - 42',
    MAN_44 = 'Man - 44',
    MAN_46 = 'Man - 46',
    MAN_48 = 'Man - 48',
    MAN_50 = 'Man - 50',
    MAN_52 = 'Man - 52',
    MAN_54 = 'Man - 54',
    MAN_56 = 'Man - 56',
    MAN_58 = 'Man - 58',
}

const trousersSizeOptions = Object.entries(TrousersSize).map((value) => {
    return {
        value: value[0],
        label: t(value[1]),
    };
});

type Props = {
    worker: Worker;
    workerSavedCallback: (worker: Worker) => void;
    allProfessions?: Profession[];
    handleProfessionChange?: (
        worker: Worker,
        selectedProfessions: Profession[],
        fromWorkerForm: boolean
    ) => Promise<void>;
};

const workersUrl = '/data-management/workers';

export const WorkerForm = ({ worker, workerSavedCallback, allProfessions, handleProfessionChange }: Props) => {
    const { t } = useLocalization();
    const [form] = Form.useForm();
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [isDataSaving, setIsDataSaving] = useState(false);
    const handleError = useErrorHandler();

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            ...worker,
            professions: worker.professions?.map(({ id }) => id) || [],
        });
    }, [form, worker]);

    const saveWorkerHandler = async (worker: Worker, form: FormInstance) => {
        try {
            setIsDataSaving(true);

            const url = worker.id ? `${workersUrl}/${worker.id}` : workersUrl;
            const workerData = form.getFieldsValue();
            const method = worker.id ? 'put' : 'post';

            const response = await Backend[method](url, workerData);
            const message =
                response.status === Backend.responseStatus.HTTP_CREATED
                    ? t('Worker successfully created')
                    : t('Worker successfully saved');

            notification.success({ message });

            workerSavedCallback(response.data);
        } catch (error) {
            handleError(error, form);
        } finally {
            setIsDataSaving(false);
        }
    };

    /**
     * Programmatically manage the Popup confirm visibility
     * to show a warning message in case the worker has an external_id
     */
    const openChangeHandler = (open: boolean) => {
        if (!open) {
            setIsConfirmOpen(open);
            return;
        }
        if (worker.external_id) {
            setIsConfirmOpen(open);
        } else {
            form.submit();
        }
    };

    /**
     * Save worker handler
     */
    const confirmSaveHandler = () => {
        setIsConfirmOpen(false);
        form.submit();
    };

    return (
        <Form form={form} onFinish={(worker) => saveWorkerHandler(worker, form)} {...formLayout}>
            <Form.Item name="id" style={{ display: 'none' }}>
                <Input />
            </Form.Item>

            <Form.Item name="provider_id" style={{ display: 'none' }}>
                <Input value={form.getFieldValue('provider_id')} />
            </Form.Item>

            <Form.Item
                name="first_name"
                rules={[
                    {
                        required: true,
                        message: t('Please enter the first name')
                    },
                    {
                        max: 150,
                        message: t('The first name may not be greater than 150 characters')
                    }
                ]}
            >
                <FloatingLabel label={t('First name')} required>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            <Form.Item
                name="last_name"
                rules={[
                    {
                        required: true,
                        message: t('Please enter the last name'),
                    },
                    {
                        max: 150,
                        message: t('The last name may not be greater than 150 characters'),
                    }
                ]}
            >
                <FloatingLabel label={t('Last name')} required>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            <Form.Item
                name="email"
                rules={[
                    {
                        type: 'email',
                        message: t('Please enter a valid email'),
                    },
                ]}
            >
                <FloatingLabel label={t('Email')}>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            <Form.Item
                name="phone_number"
                rules={[
                    {
                        min: 10,
                        message: t('The phone number may not be less than 10 characters'),
                    },
                ]}>
                <FloatingLabel label={t('Phone number')}>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            <Form.Item name="external_id">
                <FloatingLabel label={t('External ID')}>
                    <Input />
                </FloatingLabel>
            </Form.Item>

            {allProfessions && handleProfessionChange && (
                <Form.Item name="professions">
                    <FloatingLabel label={t('Professions')}>
                        <ProfessionMultiSelect
                            allProfessions={allProfessions}
                            handleProfessionChange={(worker: Worker, selectedProfessions: Profession[]) => {
                                handleProfessionChange(worker, selectedProfessions, true);

                                form.setFieldsValue({
                                    professions: selectedProfessions.map((profession) => profession.id),
                                });
                            }}
                            defaultSelections={worker.professions}
                            worker={worker}
                        />
                    </FloatingLabel>
                </Form.Item>
            )}

            <Form.Item name="shirt_size">
                <FloatingLabel label={t('Shirt size')}>
                    <Select showSearch options={shirtSizeOptions} />
                </FloatingLabel>
            </Form.Item>

            <Form.Item name="trousers_size">
                <FloatingLabel label={t('Trousers size')}>
                    <Select showSearch options={trousersSizeOptions} />
                </FloatingLabel>
            </Form.Item>

            <Form.Item name="additional_information">
                <FloatingLabel label={t('Additional information for clients')}>
                    <TextArea rows={4} />
                </FloatingLabel>
            </Form.Item>

            <Form.Item>
                <Popconfirm
                    open={isConfirmOpen}
                    onOpenChange={openChangeHandler}
                    placement="top"
                    title={
                        <>
                            {t('This worker has an External ID, therefore it may be managed from an external service.')}
                            <br />
                            {t('Your modifications might be overwritten from the external service.')}
                            <br />
                            {t('Are you sure you want to edit it?')}
                        </>
                    }
                    onConfirm={confirmSaveHandler}
                    onCancel={() => setIsConfirmOpen(false)}
                    okText={t('Yes')}
                    cancelText={t('No')}
                >
                    <Button type="primary" htmlType="button" loading={isDataSaving}>
                        {t('Save')}
                    </Button>
                </Popconfirm>
            </Form.Item>
        </Form>
    );
};
