import { useErrorHandler } from 'lib/ErrorHandling';
import { useEffect, useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { useLocalization } from 'lib/Localization';
import { Backend } from 'lib/Http/Backend';
import { UsersTable } from './UsersTable';
import { User } from 'types/models';

/**
 * Stateful component responsible for users management.
 *
 * @return {*}
 */
export const Users = () => {
    const { t } = useLocalization();
    const handleError = useErrorHandler();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [users, setUsers] = useState<User[]>([]);

    /**
     * After the component renders
     */
    useEffect(() => {
        const loadUsersData = async () => {
            try {
                setIsDataLoading(true);
                const response = await Backend.get('/account/users');
                setUsers(response.data.users);
            } catch (error) {
                handleError(error);
            } finally {
                setIsDataLoading(false);
            }
        };
        loadUsersData();
    }, [handleError]);

    return (
        <PageHeader title={t('Users')} backIcon={false} ghost={false}>
            <UsersTable users={users} loading={isDataLoading} />
        </PageHeader>
    );
};
