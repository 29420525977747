import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useLoadEntity } from 'lib/hooks/useLoadEntity';
import { GetProp, TableProps } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import qs from 'qs';
import { useLocalStorageState } from 'lib/Helpers/LocalStorageHelper';

type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: SorterResult<any>['field'];
    sortOrder?: SorterResult<any>['order'];
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

function useLoadPaginatedEntity<Entity>(
    entityName: string,
    entityUrl: string,
    defaultTableParams?: TableParams,
    onEachEntity?: (entity: Entity) => Entity
): [Entity[], TableParams, boolean, Dispatch<SetStateAction<Entity[]>>, (tableParams: TableParams) => void] {
    const [entity, loadingEntity, setEntity, loadEntity, meta] = useLoadEntity<Entity>(entityName);
    const [lastVisitedPage, setLastVisitedPage] = useLocalStorageState(entityUrl.replace(/[^a-z0-9]/gi, ''), 1);
    const [tableParams, setTableParams] = useState<TableParams>({
        ...(defaultTableParams || {}),
        pagination: {
            current: lastVisitedPage,
            pageSize: 10,
        },
    } as TableParams);

    const urlParams = useMemo(
        () => ({
            page: tableParams.pagination?.current || 1,
            limit: tableParams.pagination?.pageSize || 10,
            filters: tableParams.filters,
            sortOrder: tableParams.sortOrder && (tableParams.sortOrder === 'ascend' ? 'asc' : 'desc'),
            sortField: tableParams.sortOrder && tableParams.sortField,
        }),
        [
            tableParams.pagination?.current,
            tableParams.pagination?.pageSize,
            tableParams?.sortOrder,
            tableParams?.sortField,
            JSON.stringify(tableParams.filters),
        ]
    );

    const updateTableParams = (params: TableParams) => {
        if (params.pagination?.pageSize !== tableParams.pagination?.pageSize) {
            setEntity([]);
        }

        setTableParams(params);
    };

    const mappedEntities = useMemo(() => (onEachEntity ? entity.map(onEachEntity) : entity), [entity, onEachEntity]);

    useEffect(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: meta.total,
            },
        } as TableParams);
        setLastVisitedPage(tableParams?.pagination?.current);
    }, [entity]);

    useEffect(() => {
        loadEntity(entityUrl + (entityUrl.includes('?') ? '&' : '?') + qs.stringify(urlParams));
    }, [urlParams]);

    return [mappedEntities, tableParams, loadingEntity, setEntity, updateTableParams];
}

export { useLoadPaginatedEntity };
